import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import PricingTwo from "components/pricing/TwoPlansWithDurationSwitcher";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
// import controller from 'images/controllerLab/controller.svg'
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { FaMouse, FaRobot, FaShoppingBag, FaLinux } from 'react-icons/fa';
import marshMello from "images/controllerLab/marsh-mello.svg";
import programmer from "images/controllerLab/undraw_programmer.svg";
import PlugIcon from "images/plug-solid.svg";
import ArrowRightFromBracketIcon from "images/arrow-right-from-bracket-solid.svg";
import CodeBranchIcon from "images/code-branch-solid.svg";
import AppScreenshot1 from "images/controllerLab/app_screenshot_1.png"
import DemoControllerLabGif from "images/controllerLab/demo-controller-lab.gif"
import OpenLink from "helpers/OpenLink";

import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as MusicIcon } from "images/music-solid.svg";
import { ReactComponent as NetworkWiredIcon } from "images/network-wired-solid.svg";
import { ReactComponent as JSIcon } from "images/js-brands.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={'Unlock the full capabilities of your device, whatever it may be.'}
        description={<>
          <HighlightedText>Gamers</HighlightedText> => Enhance your control schemes
          <br/>
          <HighlightedText>Musicians</HighlightedText> => Unleash your creativity
          <br/>
          <HighlightedText>Developers</HighlightedText> => Optimize your workflow
        </>}
        cards={[{
          imageSrc: PlugIcon,
          title: 'Plug any device in',
          description: 'Listen for input events from your devices (keyboard, mouse, gamepad, or MIDI device)',
        }, {
          imageSrc: ArrowRightFromBracketIcon,
          title: 'Send anything out',
          description: 'Simulate keypresses, mouse movement/clicks, MIDI events, and run scripts',
        }, {
          imageSrc: CodeBranchIcon,
          title: 'Create logic circuits',
          description: 'Modify your events using logic operations (And, Not, XOR, Gates, and many more)',
        }]}
      />
      <MainFeature
        subheading={<Subheading>Circuit Building</Subheading>}
        heading={
          <>
          Simple but <HighlightedText>Powerful.</HighlightedText>
          </>
        }
        imageSrc={AppScreenshot1}
        imageBorder={true}
        imageDecoratorBlob={true}
        description={`
          Controller Lab's circuit building system makes it easy to remap controls in an intuitive way
          while keeping the door open for complex logic to be possible. For example, the circuits to the
          right remap a gamepad to act as a movie player controller.
        `}
        primaryButtonText={'Learn More On Discord'}
        buttonAction={OpenLink({ link: 'discord' })}
      />
      <FeatureWithSteps
        id={'getting-started'}
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={DemoControllerLabGif}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        steps={[
          {
            heading: "Download",
            description: "Download and install Controller Lab to your PC or Mac."
          },
          {
            heading: "Connect",
            description: "Connect an input block to an output block of your choice."
          },
          {
            heading: "Run",
            description: "Press your input. Controller Lab will automatically simulate the output you connected."
          }
        ]}
      />
      <MainFeature2
        id={'feature-coming'}
        subheading={<Subheading>Power Tools</Subheading>}
        primaryButtonText={"See our progress?"}
        primaryButtonUrl={""}
        heading={
          <>
            Powerful Features for <HighlightedText>Advanced</HighlightedText> Users!
          </>
        }
        description={`
          Advanced features let power users accomplish whatever they want.
        `}
        imageSrc={programmer}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MusicIcon,
            title: "MIDI Device I/O",
            description: "Plug any MIDI compatible music device into your machine to include it into your circuits.",
            iconContainerCss: tw`bg-gray-300 text-green-800`
          },
          {
            Icon: () => <FaMouse color={'black'}/>,
            title: "Mouse Acceleration",
            description: "Build a custom acceleration curve for your mouse (Windows support coming soon) or joystick.",
            iconContainerCss: tw`bg-gray-300 text-green-800`
          },
          {
            Icon: JSIcon,
            title: "Code Execution",
            description: "Link your inputs to a block allowing you to execute your own arbitrary JavaScript code.",
            iconContainerCss: tw`bg-gray-300 text-red-800`
          }
        ]}
      />
      <MainFeature2
        id={'feature-coming'}
        subheading={<Subheading>Coming soon</Subheading>}
        primaryButtonText={"See our progress?"}
        primaryButtonUrl={""}
        textOnLeft={false}
        heading={
          <>
            New <HighlightedText>Features</HighlightedText> coming Soon!
          </>
        }
        description={`
          Controller Lab receives regular updates that include exciting new features. These updates ensure that
          Controller Lab can always accomplish whatever you imagine. Here are the next big things we have
          planned.
        `}
        imageSrc={marshMello}
        showDecoratorBlob={false}
        features={[
          {
            Icon: () => <FaLinux color={'black'}/>,
            title: "Linux Support",
            description: "Run Controller lab on any Linux device, even handhelds like the Steam Deck!",
            iconContainerCss: tw`bg-gray-300 text-red-800`
          },
          {
            Icon: () => <FaRobot color={'black'}/>,
            title: "IFTTT & Zapier",
            description: "Send commands to Zapier and IFTTT, the most popular automation platforms in the world.",
            iconContainerCss: tw`bg-gray-300 text-red-800`
          },
          {
            Icon: () => <FaShoppingBag color={'black'}/>,
            title: "Circuit Marketplace",
            description: "Share your circuits and download circuits built by others to quickly solve your workflow needs.",
            iconContainerCss: tw`bg-gray-300 text-red-800`
          }
        ]}
      />
      <Pricing
        subheading={<Subheading>Cost</Subheading>}
        description={''}
        heading={
          <>
            Flexible Pricing <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            price: "FREE",
            duration: "Forever",
            mainFeature: "10 block limit",
            features: ["1 Page of Blocks", "Free updates forever"],
            primaryButtonText: 'Try Free',
            buttonAction: OpenLink({ link: 'buy', payment: 'free' })
          },
          {
            // name: "Starter",
            price: "$2",
            duration: "Monthly",
            mainFeature: "Unlimited blocks",
            features: ["Unlimited Pages", "Free updates forever"],
            buttonAction: OpenLink({ link: 'buy', payment: 'subscription' })
          },
          // {
          //   // name: "Business",
          //   price: "$50.00",
          //   duration: "Yearly",
          //   mainFeature: "Unlimited blocks",
          //   features: ["Unlimited Pages", "Free updates forever"],
          //   featured: true
          // },
          {
            // name: "Anun",
            price: "$30",
            duration: "Lifetime*",
            mainFeature: "Unlimited blocks",
            features: ["Unlimited Pages", "Free updates forever"],
            buttonAction: OpenLink({ link: 'buy', payment: 'lifetime' })
          }
        ]}
      />
      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      /> */}
      <FAQ
        id={"faqs"}
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            Frequently Asked <HighlightedText>Questions</HighlightedText>
          </>
        }
        description={''}
        faqs={[
          {
            question: "What Operatings Systems does Controller Lab support?",
            answer: "Controller Lab supports Windows 10+ and macOS 10.14+. It also supports both Intel and M1 Macs.",
          },
          {
            question: `Can you add "x" feature to ControllerLab?`,
            answer:
              "We want to make Controller Lab as powerful as possible for our users. If there's a feature you think is missing, join our Discord server at the link at the top of the page and send us a message!"
          },
          {
            question: "What kind of payment methods do you accept?",
            answer:
              "We use Gumroad as our payment processor. At this time, Gumroad accepts credit/debit cards, and PayPal."
          },
          {
            question: "Will Controller Lab work with my device?",
            answer:
              "While we want to support every device possible, we can't guarantee compatibility with everything. You can try your device out before buying by using the Free Tier. If you find a device that doesn't work the way you expect it to, reach out to us on Discord (link at the top of the page) or email us at contact@controllerlab.io."
          },
          {
            question: "Do you support modifying mouse acceleration curves?",
            answer:
              "Yes! But only on macOS right now. We hope to add support for Windows soon."
          },
          {
            question: "Do you plan to add Linux support?",
            answer:
              "This is something we would really like to do eventually, but it's not on our immediate priorities list."
          },
          {
            question: "I don't want to pay until I know Controller Lab works for my purpose. Is there a trial?",
            answer:
              "Yes! Controller Lab is free, so long as your use case uses less than 10 blocks. Using more than 10 blocks requires a purchase."
          },
        ]}
      />
      {/* <GetStarted/> */}
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
}
