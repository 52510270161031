import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCsVypwjlfJuCs6WIrD6cg7cURjKK8xbzU",
  authDomain: "controller-lab-64be8.firebaseapp.com",
  projectId: "controller-lab-64be8",
  storageBucket: "controller-lab-64be8.appspot.com",
  messagingSenderId: "487495869081",
  appId: "1:487495869081:web:159835045e1390742e4e08",
  measurementId: "G-RJMGVM6RKK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);