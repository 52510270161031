import { getAnalytics, logEvent } from "firebase/analytics";

const logLinkClick = ( linkType ) => {
  const analytics = getAnalytics();
  logEvent(analytics, 'select_content', {
    content_type: 'button',
    content_id: linkType
  });
}

export default function ({ link, payment }) {
  return () => {
    if (link === 'discord') {
      logLinkClick(link);
      window.open('https://discord.gg/dxED4vMv7T')
    } else if (link === 'buy') {
      logLinkClick(payment || 'store');
      if (payment === 'free') {
        window.open('https://store.controllerlab.io/l/free?price=0&wanted=true')
      } else if (payment === 'subscription') {
        window.open('https://store.controllerlab.io/l/subscription')
      } else if (payment === 'lifetime') {
        window.open('https://store.controllerlab.io/l/lifetime?wanted=true')
      } else {
        window.open('https://store.controllerlab.io')
      }
    }
  }
}